import React from "react";
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/contact/contacthero";
import Contactinfo from "../sections/contact/contactinfo.js"
import Contactcontent from "../components/NewFind.js"
import Head from "../components/head"
import HeroComponent from "../components/ImageHero.js";
import imgVideo from "../assets/image/jpeg/map.jpg";

const Contact = () => {
  return (
    <>
     <Head title="Contact Dermamina" image="/images/dermamina-home.jpg"  description="experts in non surgical nose job Rhinoplasty London" keywords="Nose jobs, Rhinoplasty, Liquid nose job, Liquid rhinoplasty, Surgical nose job, Nose filler, Rhinoplasty filler, Non surgical rhinoplasty, No surgery nose job, Pain free nose job"/>
      <PageWrapper headerDark>
      <HeroComponent
    itext='<span style="color: #1a1a1a;">Contact </span> <span style="color: #00aec7;">Dermamina</span>'
    secondText="We look forward to assisting you!"
    videoAlt="Contact Dermamina"
    imgVideo={imgVideo} // Specify your image URL here
  />
    
       <Contactinfo />
      <Contactcontent />
      </PageWrapper>
    
    </>
  );
};
export default Contact;